/**
 * @file Language keys
 * @module language.key
 * @author Surmon <https://github.com/surmon-china>
 */

export enum LanguageKey {
  APP_SLOGAN = 'app-slogan',
  CATEGORY_INSIGHT = 'insight',
  CATEGORY_CODE = 'code',
  PAGE_HOME = 'home',
  PAGE_GITHUB = 'github',
  PAGE_PHOTOGRAPHY = 'photography',
  PAGE_ABOUT = 'about',
  PAGE_SPONSOR = 'sponsor',
  PAGE_GUESTBOOK = 'guestbook',
  PAGE_APP = 'app',
  PAGE_ARCHIVE = 'archive',
  STATISTIC_ARTICLES = 'statistic-articles',
  STATISTIC_COMMENTS = 'statistic-comments',
  STATISTIC_TAGS = 'statistic-tags',
  STATISTIC_TODAY_VIEWS = 'statistic-today-views',
  STATISTIC_TOTAL_VIEWS = 'statistic-total-views',
  STATISTIC_TOTAL_UPVOTES = 'statistic-total-upvotes',
  STATISTIC_AVERAGE_EMOTION = 'statistic-average-emotion',
  EMPTY_PLACEHOLDER = 'common-empty-placeholder',
  NOT_FOUND = 'not-found',
  BACK_TO_HOME_PAGE = 'back-to-home-page',
  GUESTBOOK_SLOGAN = 'guestbook-banner-slogan',
  AD = 'ad',
  FEEDBACK = 'feedback',
  TO_TOP = 'to-page-top',
  TO_BOTTOM = 'to-page-bottom',
  ORIGIN_ORIGINAL = 'original',
  ORIGIN_REPRINT = 'reprint',
  ORIGIN_HYBRID = 'hybrid',
  ACTION_ON = 'on',
  ACTION_OFF = 'off',
  MUSIC_PLACEHOLDER = 'music-placeholder',
  SEARCH_PLACEHOLDER = 'search-input-placeholder',
  ANNOUNCEMENT_PLACEHOLDER = 'announcement-empty-placeholder',
  CATEGORY_UNCATEGORIZED = 'category-uncategorized',
  TAG_PLACEHOLDER = 'tag-placeholder',
  LIST_NO_MORE_DATA = 'no-more',
  ARTICLE_TITLE = 'article-title',
  ARTICLE_VIEWS = 'article-views',
  ARTICLE_PLACEHOLDER = 'article-empty-placeholder',
  ARTICLE_READ_ALL = 'read-all-article-content',
  ARTICLE_RENDERING = 'rendering',
  ARTICLE_LIST_LOADMORE = 'loadmore',
  ARTICLE_LIST_LOADING = 'loading',
  ARTICLE_FEATURED = 'article-featured',
  ARTICLE_FEATURED_SHORT = 'article-featured-short',
  COMMENT_LIST_EMPTY = 'comment-list-placeholder',
  COMMENT_UPVOTE = 'comment-likes',
  COMMENT_DOWNVOTE = 'comment-dislikes',
  COMMENT_REPLY = 'reply-comment',
  COMMENT_DELETE = 'delete-comment',
  COMMENT_DELETE_CONFIRM = 'delete-comment-confirm',
  COMMENT_REPLY_CANCEL = 'cancel-reply-comment',
  COMMENT_MODERATOR = 'comment-moderator',
  COMMENT_SORT_OLD = 'oldest-comments',
  COMMENT_SORT_NEW = 'newest-comments',
  COMMENT_SORT_HOT = 'hottest-comments',
  COMMENT_POST_NAME = 'comment-author-name',
  COMMENT_POST_EMAIL = 'comment-author-email',
  COMMENT_POST_SITE = 'comment-author-site-url',
  COMMENT_POST_CONTENT = 'comment-content',
  COMMENT_POST_PLACEHOLDER = 'comment-box-placeholder',
  COMMENT_POST_ERROR_CONTENT = 'comment-submit-failed-of-content-error',
  QUERY_PARAMS_ERROR = 'query-params-error',
  POST_ACTION_ERROR = 'post-requiest-failed-of-other-error',
  SUBMIT = 'submit-data',
  SUBMITTING = 'submitting',
  MOMENT_AM = 'moment-am',
  MOMENT_PM = 'moment-pm',
  MOMENT_JUST_NOW = 'moment-just-now',
  MOMENT_MINUTES = 'moment-minutes',
  MOMENT_HOURS = 'moment-hours',
  MOMENT_WEEKS = 'moment-weeks',
  MOMENT_DAYS = 'moment-days',
  MOMENT_MONTHS = 'moment-months',
  MOMENT_YEAR = 'moment-year',
  MOMENT_AGO = 'moment-ago'
}
